<template>
    <div>
        <div class="contacts_header">
            <h1>Города</h1>
            <vue-add-button @onClick='onAdd'>
                Добавить город
            </vue-add-button>    
        </div>   
        <v-data-table
            :headers="headers"
            :items="contacts"
            :loading="loading"
            :options.sync="options"
            disable-sort
        >
            <template v-slot:[`item.actions`]="{ item }">
                <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
            </template>
        </v-data-table> 
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit'>
            <v-form
                ref="form"
                class="bus_form"
                lazy-validation
            >
                <div>
                    <input-content
                        :items="inputItems"
                        :model="model"
                    />
                </div>
            </v-form>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить город?"'/>
    </div>
</template>

<script>
import VueAddButton from '@/components/ui/vueAddButton.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import { citiesService } from '@/services/cities.service.js'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        VueAddButton,
        VueDialog,
        InputContent,
        vueDeleteDialog,
        VueTableActions
    },
    data: () => ({
        deleteId: null,
        loading: false,
        dialog: false,
        deleteDialog: false,
        contacts: [],
        headers: [
            { text: 'Название', value: 'name' },
            { text: '', value: 'actions' },
        ],
        model: {},
        dialogOptions: {
            width: '500',
            title: 'Город',
            button: 'Сохранить'
        },
        inputItems: [
            {
                type: 'text',
                label: 'Название',
                rules: 'required',
                model: 'name',
            },
        ]
    }),
    created() {
        this.getList()
    },
    methods: {
        closeDialog() {
            if (this.$refs.form) {
                this.$refs.form.reset()
            }
            this.dialog = false
            this.model = {}
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        async deleteHandler() {
            await citiesService.deleteCity(this.deleteId)
            this.getList()
            try {
                this.$loading(true)
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
                this.closeDelete()
            }
        },
        onEdit(item) {
            this.model = this.$copy(item)
            this.dialog = true
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        onAdd() {
            this.dialog = true
        },
        async getList() {
            try {
                this.loading = true
                let params = {
                    page: this.options.page, 
                    size: this.options.itemsPerPage
                }
                const res = await citiesService.getCities(params)
                this.contacts = res.results
            } catch(e) {
                console.error(e);
            } finally {
                this.loading = false
            }
        },
        async onSubmit() {
            if (this.$refs.form) {
                if (!this.$refs.form.validate()) return
            }
            try {
                this.$loading(true)
                const postData = this.$copy(this.model)
                if (this.model.id) {
                    await citiesService.patchCity(postData)
                } else {
                    await citiesService.postCity(postData)
                }
                this.getList()
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
                this.closeDialog()
            }
        }
    } 
}
</script>

<style lang="scss" scoped>
h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #52B1BA;
    margin-bottom: 0;
}
.contacts_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
</style>